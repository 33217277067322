import React from "react"
import { Link } from "gatsby"
import { motion } from 'framer-motion'
import {StaticImage} from "gatsby-plugin-image"
import Layout from "../components/layout"
import Hero from "../components/hero"
import Cta from "../components/cta"
import Seo from "../components/seo"
import TestimonialSection from "../components/testimonials-section"
import ArrowRight from "../components/svg/arrowRight"
import Collapsible from 'react-collapsible';
import ChevronDown from "../components/svg/chevronDown"

const StartupWebDesignPage = () => (
    <Layout>
        <Seo 
            title="Startup web design & product design | Sydney startup designers" 
            description="Launch Lab are experienced Australian startup web designers. We help startups design and develop the first version of their product and website."
            pathname="/startup-web-designers/"
            serviceType="Startup web design"
        />

        <Hero
            h1="Startup Web Designers in Australia"  
            h2="We're an established startup web design and development company in Sydney &amp; Canberra"
        /> 

        <section className="bg-purple with-pad">
            <div className="container-lg">
                <div className="row">
                <div className="work-jumbo-wrapper with-radius">
                    <motion.div 
                        className="work-jumbo-wrapper with-radius"
                        initial="hidden"
                        animate={{ opacity: [0, 0.25, 0.5, 0.75, 1], y: [120, 0], scale: [0.9, 1] }} 
                        transition={{ ease: "easeOut", delay: 0.5, duration: 1 }}
                    >
                        <StaticImage 
                            src="../images/launch-lab-work-2024.png"
                            alt="Screengrabs of some of the websites and applications that Launch Lab have designed and developed"
                            formats={["AUTO", "WEBP", "AVIF"]}
                            placeholder="none"
                            backgroundcolor="white"
                            layout="fullWidth"
                            quality="100%"
                            className="with-radius"
                        />
                    </motion.div>
                </div>
                <div className="flex flex-jc">
                    <Link to="/portfolio/" className="btn btn-lg btn-pink">
                    <span className="flex flex-ac">
                        View all work
                        <ArrowRight />
                    </span>
                    </Link>
                </div>
                </div>
            </div>
        </section>

        <section className="section-pad" id="anchor">
            <div className="container">
                <div className="row">
                    <div className="cols">
                        <div className="col-26">
                            <h3
                            >Website &amp; product design for startups</h3>
                        </div>

                        <div className="col-67 long-text">
                            <p>Launch Lab help startups get started.</p>
                            <p>We have designed web apps, dashboards, MVPs, marketing websites and landing pages for HealthTech, EduTech, FinTech, ConstructionTech, Saas and marketplace startups.</p>
                            <p>Over the past 9 years we've <strong>designed and developed more than 40 startups</strong>. This includes working with first time bootstrapped founders through to funded and acquired startups.</p>               
                            <p>We're also trusted by Government, universities and established companies to deliver web based solutions.</p>
                            <p>Whether you just need web design / product design services or you need an end-to-end <Link to="/startup-web-application-development/">startup development team</Link>, we'd love to discuss your product.</p>
                            <div className="btn-row">
                                <Link to="/contact/" className="btn btn-lg btn-purple">
                                    <span className="flex flex-ac">
                                        Contact us
                                        <ArrowRight />
                                    </span>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <div className="container">
            <div className="row">
            <div className="section-line"></div>
            </div>
        </div>

        <div class="container">
            <div className="row">
                <div className="well bg-purple">
                    <h4>AWS &amp; Webflow for startups partner</h4>
                    <p>Launch Lab is a <Link to="/webflow-developer/">Webflow for Startups</Link> partner and an <Link to="/aws-infrastructure/">AWS Activate</Link> partner. Contact us to find out how you can benefit from these startup programs.</p>
                </div>
            </div>
        </div>

        <div className="container">
            <div className="row">
            <div className="section-line"></div>
            </div>
        </div>

        <section className="section-pad-sm">
            <div className="container">
                <div className="row">   
                    <h4 className="text-center">Recent projects</h4>
                    <motion.div 
                        className="porti-row mt-3"
                        initial={{ opacity: 0, y: 120 }}
                        whileInView={{ opacity: [0, 0.25, 0.5, 0.75, 1], y: 0, scale: [0.9, 1] }}
                        viewport={{ once: true }}
                        transition={{ ease: "easeOut", delay: "0.25", duration: 0.5 }}
                    >
                        <Link to="/case-study-giraffe-webflow-website/" className="porti-item">
                            <div className="porti-item-img bg-noise giraffe">
                                <StaticImage 
                                    src="../images/giraffe.png"
                                    alt="A collage of the Giraffe website screens"
                                    formats={["AUTO", "WEBP", "AVIF"]}
                                    placeholder="none"
                                    backgroundcolor="white"
                                    layout="fullWidth"
                                    quality="100%"
                                />
                            </div>
                            <div className="porti-item-text">
                                <h3>Giraffe</h3>
                                <p>We designed and developed a new Webflow website for this Sydney startup.</p>
                                <div className="flex flex-wrap">
                                    <div className="work-pill website">Website</div>
                                    <div className="work-pill webapp">Webflow</div>
                                    <div className="work-pill cms">CMS</div>
                                </div>
                            </div>
                        </Link>

                        <Link to="/feezy-case-study/" className="porti-item">
                            <div className="porti-item-img">
                                <StaticImage 
                                    src="../images/feezy-website.png"
                                    alt="Feezy website home page"
                                    formats={["AUTO", "WEBP", "AVIF"]}
                                    placeholder="none"
                                    backgroundcolor="white"
                                    layout="fullWidth"
                                    quality="100%"
                                />
                            </div>
                            <div className="porti-item-text">
                                <h3>Feezy</h3>
                                <p>We designed and developed a 2-sided marketplace web application for this Sydney, Australia, based startup.</p>
                                <div className="flex flex-wrap">
                                    <div className="work-pill website">Website</div>
                                    <div className="work-pill webapp">Web App</div>
                                    <div className="work-pill startup">Startup</div>
                                </div>
                            </div>
                        </Link>
                        <Link to="/enabli-case-study/" className="porti-item">
                            <div className="porti-item-img">
                                <StaticImage 
                                    src="../images/enabli-screens.png"
                                    alt="A collage of the Enabli website and application screens"
                                    formats={["AUTO", "WEBP", "AVIF"]}
                                    placeholder="none"
                                    backgroundcolor="white"
                                    layout="fullWidth"
                                    quality="100%"
                                />
                            </div>
                            <div className="porti-item-text">
                                <h4>Enabli</h4>
                                <p>We designed and developed HR Tech software and a marketing website for this Sydney-based startup.</p>
                                <div className="flex flex-wrap">
                                    <div className="work-pill website">Website</div>
                                    <div className="work-pill webapp">Web App</div>
                                    <div className="work-pill startup">Startup</div>
                                </div>
                            </div>
                        </Link>

                        <Link to="/fixitdoc-case-study/" className="porti-item">
                            <div className="porti-item-img">
                                <StaticImage 
                                    src="../images/fixitdoc-screens.png"
                                    alt="FixitDoc mobile aplication"
                                    formats={["AUTO", "WEBP", "AVIF"]}
                                    placeholder="none"
                                    backgroundcolor="white"
                                    layout="fullWidth"
                                    quality="100%"
                                />
                            </div>
                            <div className="porti-item-text">
                                <h4>FixitDoc</h4>
                                <p>We developed a Webflow website and a mobile app which makes use of the Webflow API.</p>
                                <div className="flex flex-wrap">
                                    <div className="work-pill website">Website</div>
                                    <div className="work-pill cms">CMS</div>
                                    <div className="work-pill mobileapp">Mobile App</div>
                                    <div className="work-pill startup">Startup</div>
                                </div>
                            </div>
                        </Link>

                    </motion.div>
                </div>
                
                <div className="flex flex-jc">
                    <Link to="/portfolio/" className="btn btn-lg btn-purple">
                    <span className="flex flex-ac">
                        View all projects
                        <ArrowRight />
                    </span>
                    </Link>
                </div>
            </div>
        </section>

        <section className="section-pad bg-black">
            <div className="container">
                <div className="row">
                    <div className="cols">
                        <div className="col-26">
                            <h5 className="title">
                                FAQs
                            </h5>
                        </div>
                        <div className="col-67">
                            <Collapsible 
                                transitionTime={100}
                                trigger={
                                <>
                                    What does it cost to design a startup website? <ChevronDown />
                                </>
                                }
                            >
                                <p>It depends. The brief and the project requirements will have a big impact on this. You can spend as little as a couple of thousand dollars or could spend in excess of $30,000 on UI and UX design.</p>
                                <p>Our preferred approach for early stage startups is to adopt a lean methodology to help you get to market with a professional design on a startup budget.</p>
                            </Collapsible>
                            <Collapsible
                                transitionTime={100}
                                trigger={
                                <>
                                    How long does is take to design a startup website or application? <ChevronDown />
                                </>
                                }
                            >
                                <p>As per the question above, project timelines depend on the unique requirements of the project. Landing pages can be designed within a few days while full web applications can take weeks or even months to plan and design.</p>
                            </Collapsible>
                            <Collapsible 
                                transitionTime={100}
                                trigger={
                                <>
                                    Do you offer startup web development services? <ChevronDown />
                                </>
                                }
                            >
                                <p>Yes. We also develop most of the startups we design. Our development services include frontend and backend development. We've worked with startups from initial wireframes through to design, development, testing and go live but we're also comfortable working with your backend developers if you have your own team.</p>
                            </Collapsible>
                            <Collapsible 
                                transitionTime={100}
                                trigger={
                                <>
                                    I need investment. Can you help? <ChevronDown />
                                </>
                                }
                            >
                                <p>Unless we're taking equity in your startup we do require that you have a budget in place. Once your product is in market and generating revenue we'll happily help with an investment strategy.</p>
                            </Collapsible>
                            <Collapsible 
                                transitionTime={100}
                                trigger={
                                <>
                                    Where are you based? <ChevronDown />
                                </>
                                }
                            >
                                <p>We're based in Sydney while our CTO (yes, we develop startups too) is based in Canberra. That doesn't mean we're restricted by geography. We've worked with founders in Melbourne, Perth, regional NSW, the Northern Territory and Brisbane.</p>
                            </Collapsible>
                            <Collapsible 
                                transitionTime={100}
                                trigger={
                                <>
                                    What technologies do you use? <ChevronDown />
                                </>
                                }
                            >
                                <p>For marketing websites we use either Gatsby.js, Astro or Webflow. It depends on your requirements.</p>
                                <p>If using Gatsby, and if you need a CMS, we recommend a headless CMS like Contentful or Storyblok.</p>
                                <p>When developing applications for startups we usually use Python, React and AWS.</p>
                            </Collapsible>
                            <Collapsible 
                                transitionTime={100}
                                trigger={
                                <>
                                    Do you offer SEO services? <ChevronDown />
                                </>
                                }
                            >
                                <p>We'll help with your on-page SEO requirements.</p>
                                <p>For off-page SEO and link building we can introduce you to an SEO expert.</p>
                            </Collapsible>
                        </div>
                    </div>
 
                </div>
            </div>
        </section>

        <TestimonialSection />                              

        <Cta 
            text="If you are planning to design and develop a startup in Australia we'd love to discuss how Launch Lab can help you get started."
        />
    </Layout>
)

export default StartupWebDesignPage